import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const FigureContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => props.justifyContent || "center"};
  align-items: ${props => props.alignItems || "flex-end"};
  margin-top: 2rem;
`

const Wrapper = styled.div`
  flex: 0 0 auto;
`

const Figure = ({ src, alt, width, children }) => (
  <Wrapper>
    <figure
      css={css`
        margin: 2rem;
      `}
    >
      <img
        src={src}
        alt={alt}
        css={css`
          width: ${width ? `${width}px` : "auto"};
          max-width: 100%;
        `}
      />
      <figcaption
        css={css`
          margin-top: 1rem;
        `}
      >
        {children}
      </figcaption>
    </figure>
  </Wrapper>
)

export { FigureContainer, Figure }
