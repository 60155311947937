import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '../../layouts/styles/theme'

const StaticBlock = styled.div`
  flex: 0 0 100%;
  margin-top: 3rem;
  padding-right: 0rem;
  ${mq[0]} {
    padding-right: 5rem;
  }
  ${({ cols }) => (cols === 2 ? `${mq[2]} { flex: 0 0 50%; }` : null)}
  p {
    max-width: 700px;
  }
`

StaticBlock.defaultProps = {
  cols: 1
}

const Container = ({ cols, children, bgImg }) => (
  <div
    css={css`
      display: flex;
      flex-flow: row wrap;
      justifyc-ontent: flex-start;
      align-items: stretch;

      ${bgImg
    ? `
        ${mq[2]} {
          background-image: url(${bgImg});
          background-repeat: no-repeat;
          background-size: 60%;
          background-position: 40% 4rem;
          background-color: rgba(255, 255, 255, 0.1);
        }
      `
    : null}
    `}
  >
    {React.Children.map(children, child => React.cloneElement(child, { cols }))}
  </div>
)

export { Container, StaticBlock }
