import React from 'react'
import styled from '@emotion/styled'
import { mq } from '../../layouts/styles/theme'
import { withTheme } from 'emotion-theming'

const Blockquote = styled.blockquote`
  font-size: 1.4222rem;
  font-weight: 300;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  ${mq[1]} {
    font-size: 2.133rem;
  }
`

const Cite = styled.p`
  font-size: 1rem;
  margin-top: 0.5rem;
  ${mq[1]} {
    font-size: 1.2rem;
  }
`
const Quote = ({ cite, children }) => (
  <Blockquote cite={cite}>
    &laquo;{children}&raquo;
    <Cite>{cite}</Cite>
  </Blockquote>
)

export default withTheme(Quote)
