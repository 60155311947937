import React from "react"
import { mq } from "../layouts/styles/theme"
import styled from "@emotion/styled"

const Container = styled.div`
  height: 280px;
  background-color: transparent;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 3rem;
`

const LeftImage = styled.div`
  flex: 0 0 100%;
  max-width: 300px;
  background-image: ${props => `url(${props.src});`};
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`

const RightImage = styled.div`
  flex: 0 0 100%;
  max-width: 220px;
  background-image: ${props => `url(${props.src});`};
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: none;
  ${mq[0]} {
    display: block;
  }
`

const Left = styled.div`
  flex: 7 7;
  height: 100%;
  background-color: ${props => props.theme.colors.primary};
`

const Right = styled.div`
  flex: 5 5;
  height: 100%;
  background-color: ${props => props.theme.colors.secondary};
`
const Belt = ({ leftImage, rightImage }) => (
  <Container>
    <Left />
    <LeftImage src={leftImage} />
    <RightImage src={rightImage} />
    <Right />
  </Container>
)

export default Belt
